import "../stylesheets/service";

//hamburger menu
// $(function test(){
//   $(".nav_hbg div").on("click", function() {
//       $(this).toggleClass("clicked");
//       $(".nav_menu").toggleClass("opened");
//   });
// });
document.addEventListener('DOMContentLoaded', ()=>{
  var humburger = document.querySelector(".nav_hbg");
  humburger.addEventListener("click", ()=> {
    humburger.classList.toggle('clicked');
    document.querySelector(".nav_menu").classList.toggle('opened');
  });
});

window.addEventListener("scroll", function(){
  //initiation
  var header = document.querySelector("header");
  var changelogo = document.getElementById("logo");
  var changecaption = document.getElementById("caption");

  //function when scroll
  header.classList.toggle("sticky", window.scrollY > 0);
  if (window.scrollY > 0){
    // changelogo.src = "img/logo-lumiere-small.png"
    changelogo.classList.add("logo-sticky")
    changecaption.classList.add("caption-hide")
  } else {
    // changelogo.src = "img/logo-lumiere.png"
    changelogo.classList.remove("logo-sticky")
    changecaption.classList.remove("caption-hide")
  }

})
